.step_style div div .ant-steps-item-title {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #3F3F3F;
    margin-top: 60px;
}

.step_style div div .ant-steps-item-description {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #727F97;
    margin-top: 8px;
}

.test_img_processing .ant-steps-item-container:after {
    position: absolute;
    top: 30px;
    right: 55%;
    content: "";
    mask-image: url("../../assets/img/processing.svg");
    background-color: #B0B6BD;
    z-index: 999999;
    width: 40px;
    height: 40px;
}

.test_img_transit .ant-steps-item-container:after {
    position: absolute;
    top: 30px;
    right: 55%;
    content: "";
    mask-image: url("../../assets/img/transit.svg");
    background-color: #B0B6BD;
    z-index: 999999;
    width: 40px;
    height: 40px;
}

.test_img_out .ant-steps-item-container:after {
    position: absolute;
    top: 30px;
    right: 55%;
    content: "";
    mask-image: url("../../assets/img/out.svg");
    background-color: #B0B6BD;
    z-index: 999999;
    width: 40px;
    height: 40px;
}

.test_img_delivery .ant-steps-item-container:after {
    position: absolute;
    top: 30px;
    right: 35%;
    content: "";
    mask-image: url("../../assets/img/delivered.svg");
    background-color: #B0B6BD;
    z-index: 999999;
    width: 40px;
    height: 40px;
}

.ant-steps-item-active .ant-steps-item-container:after {
    background-color: #33CCFF;
}

@media only screen
and (max-device-width: 768px) {

    .test_img_processing .ant-steps-item-container:after {
        display: none;
    }

    .test_img_transit .ant-steps-item-container:after {
        display: none;
    }

    .test_img_out .ant-steps-item-container:after {
        display: none;
    }

    .test_img_delivery .ant-steps-item-container:after {
        display: none;
    }

    .step_style div div .ant-steps-item-title {
        margin-top: 0;
        text-align: left;
    }

    .step_style div div .ant-steps-item-description {
        text-align: left;
    }
}

