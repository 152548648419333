.popup_wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(190, 190, 190, .6);
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    width: 872px;
    height: 635px;
    background: #FAFAFA;
    position: relative;
    padding: 80px 72px;
    box-sizing: border-box;
    display:  flex;
    flex-direction: column;
    align-items: center;
}

.close_icon {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
}

.search_block {
    margin-bottom: 64px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search_input {
    border: 1px solid #B0B6BD;
    padding: 10px 12px;
    width: 300px;
    outline: none;

    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #727F97;
    height: 40px;
    box-sizing: border-box;
}

.search_button {
    width: 196px;
    height: 40px;
    background: #33CCFF;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border: none;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

.process_dot {
    padding: 10px;
    background: url("../../assets/img/proccess_dot_img.png") #33CCFF center no-repeat;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6px;
}

.finish_dot {
    padding: 10px;
    background: url("../../assets/img/tick.png") #33CCFF center;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6px;
}

.dot_style {
    padding: 10px;
    background: #DDDDDD;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6px;
}

.details_block {
    width: 502px;
    margin-top: 57px;
}

.details_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.detail_title {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #3F3F3F;
    min-width: max-content;
    padding-right: 8px;
}

.detail_value {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #727F97;
    min-width: 120px;
    text-align: left;
    padding-left: 8px;
}

.dash {
    width: 100%;
    height: 50%;
    border-bottom: 1px dashed #B0B6BD;
}

.error {
    color: darkred;
    font-family: 'Lato', sans-serif;
    display: inline;
    font-size: 18px;
    font-weight: 400;
    padding: 7px 10px 8px;
    transition: all 0.5s ease;
}

@media only screen
and (max-device-width: 768px) {
    .content {
        width: 100%;
        height: 100vh;
        overflow: scroll;
        padding: 30px 16px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .details_block {
        width: 100%;
        margin-top: 57px;
    }


    .search_input {
        border: 1px solid #B0B6BD;
        padding: 10px 12px;
        width: 100%;
        margin: 0 16px;
        outline: none;

        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #727F97;
        height: 40px;
        box-sizing: border-box;
    }

    .search_button {
        width: 196px;
        height: 40px;
        background: #33CCFF;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border: none;
        cursor: pointer;
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
    }

    .search_block {
        margin-top: 40px;
    }


}
