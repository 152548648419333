.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 120px;
}

.form_block {
    margin-top: 50px;
    width: 450px;
}

.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

.input {
    width: 100%;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    color: #727f97;
    background: #fff;
    border: 1px solid #b0b6bd;
    padding: 10px;
    outline: none;
    box-sizing: border-box;
    margin-bottom: 15px;
}

.error_input {
    width: 100%;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    color: #727f97;
    background: #fff;
    border: 1px solid red;
    padding: 10px;
    outline: none;
    box-sizing: border-box;
    margin-bottom: 15px;
}

.textarea {
    width: 100%;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    color: #727f97;
    background: #fff;
    border: 1px solid #b0b6bd;
    padding: 10px;
    outline: none;
    box-sizing: border-box;
    height: 100px;
}

.title {
    margin-bottom: 20px;
    color: #3f3f3f;
    font-size: 24px;
    font-weight: 700;
}

.my_btn {
    color: #fff;
    font-family: 'Lato', sans-serif;
    display: block;
    font-size: 18px;
    font-weight: 400;
    padding: 10px 34px;
    background: #33ccff;
    border: none;
    margin-top: 15px;
    cursor: pointer;
    transition: .5s all ease;
}

.my_btn:hover {
    background: #131313;
    color: #fff;
    transition: .5s all ease;
    border: none;
}

.contact_response {
    color: #943737;
    font-family: 'Lato', sans-serif;
    display: inline;
    font-size: 18px;
    font-weight: 400;
    padding: 7px 10px 8px;
    transition: all 0.5s ease;
}

@media only screen
and (max-device-width: 768px) {
    .flex {
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
    }

    .form_block {
        margin-top: 50px;
        width: 100%;
        padding: 0 16px;
        box-sizing: border-box;
    }

    .contacts_block {
        padding: 0 16px;
    }
}
