.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    width: 1000px;
}

.search_block {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid #00000021;
    margin-bottom: 30px;
}

.search_title {
    color: #3f3f3f;
    font-size: 24px;
    font-weight: 700;
}

.my_input {
    font-size: 16px;
    color: #727f97;
    background: #fff;
    border: 1px solid #b0b6bd;
    padding: 10px !important;
    width: 600px;
    margin-right: 8px;
    outline: none;
}

.flex {
    display: flex;
    align-items: center;
}

.go_button {
    color: #fff;
    font-family: 'Lato', sans-serif;
    display: inline;
    font-size: 18px;
    font-weight: 400;
    padding: 7px 10px 8px;
    background: #cccccc;
    border: 2px solid #cccccc;
    transition: all 0.5s ease;
    cursor: pointer;
}

.go_button:hover {
    background: #000;
    border: 2px solid #000;
    transition: all 0.5s ease;
}

.error {
    color: darkred;
    font-family: 'Lato', sans-serif;
    display: inline;
    font-size: 18px;
    font-weight: 400;
    padding: 7px 10px 8px;
    transition: all 0.5s ease;
}

.text_block {
    width: 65%;
}

.login_title {
    color: #2a2a2a;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
}

.login_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.form_block {
    width: 320px;
}

.short_input {
    width: 100%;
    margin-bottom: 10px;
    font-size: 16px;
    color: #727f97;
    background: #fff;
    border: 1px solid #b0b6bd;
    padding: 10px;
}

.input_label {
    margin-bottom: 4px;
    color: #3f3f3f;
}

.submit_button {
    color: #fff;
    font-family: 'Lato', sans-serif;
    display: block;
    font-size: 18px;
    font-weight: 400;
    padding: 10px 34px;
    background: #33ccff;
    border: 2px solid #33ccff;
    transition: all 0.5s ease;
    cursor: pointer;
}

.submit_button:hover {
    background: #000;
    color: #fff;
    transition: all 0.5s ease;
    border: 2px solid #000;
}

.slogan {
    background: #000;
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    font-style: normal;
    line-height: 32px;
    color: #fff;
    padding: 18px 20px;
    margin: 2em 0;
    border-left: 0;
    text-align: center;
}

.gold {
    color: #c2a43b;
}

.img_row {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 30px;
}

.img_row div {
    cursor: pointer;
}

.img_text {
    text-align: center;
}

.pre_footer {
    width: 100%;
    background: #333333;
    border-top: 1px solid #e6e9eb;
    padding: 20px 0 0;
    color: #415161;
    font-size: 14px;
    display: flex;
    justify-content: center;
}

.links_title {
    color: #33ccff;
    font-size: 13px;
    margin-bottom: 4px;
    cursor: pointer;
}

.footer_link {
    color: #3f3f3f;
    text-decoration: none;
    transition: .5s all ease;
}

.footer_link:hover {
    color: #c2a43b;
    transition: .5s all ease;
}

.links_column {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

}

.links_block {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    width: 162px;
}

.pre_footer_wrapper {
    display: flex;
    justify-content: space-between;
}

.footer {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    background-color: #2b2b2b;
    width: 100%;
    color: #CCC;
}

.footer_text {
    text-align: left;
    font-size: 11px;
}

.terms {
    color: #3f3f3f;
    cursor: pointer;
}

.terms:hover {
    color: #c2a43b;
}

@media only screen
and (max-device-width: 768px) {
    .container {
        width: 100%;
    }

    .img_row {
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;
    }

    .my_input {
        width: 100%;
    }

    .pre_footer_wrapper {
        flex-direction: column;
        align-items: center;
    }

    .login_block {
        flex-direction: column;
    }

    .search_block {
        flex-direction: column;
        padding: 20px 16px;
    }
}
