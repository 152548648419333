.container {
    width: 1000px;
}

.header {
    width: 100%;
    background-color: #333333;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login {
    font-weight: 400;
    color: #ffffff;
    text-align: right;
    cursor: pointer;
}

.title {
    font-family: 'Lato', sans-serif;
    font-size: 50px;
    font-weight: 700;
    line-height: 60px;
    color: #415161;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: -1px;
    margin: 22px 0 20px;
    text-align: center;
}

.navbar {
    display: flex;
    gap: 28px;
    justify-content: center;
    flex-wrap: wrap;
}

.nav_item {
    min-width: max-content;
    color: #33ccff;
    text-decoration: none;
    transition: .3s all ease;
}

.nav_item:hover {
    color: #333333;
    transition: .3s all ease;
}

.slider_wrapper {
    margin-top: 30px;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.my_slider {
    width: 100%;
    height: 383px;
}

.bg {
    width: 100%;
    height: 383px;
}

.bg img {
    width: 100%;
    height: 383px;
}

.card_content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
}

.arrow_prev {
    cursor: pointer;
    position: absolute;
    left: 50px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
}

.arrow_next {
    cursor: pointer;
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
}

.text_slider {
    position: absolute;
    left: 0;
    top: 60%;
    z-index: 2;
    padding: 0.7em 1em !important;
    line-height: 1.6em;
    text-align: left;
    background: rgba(0, 0, 0, .3);
    color: #fff;
    font-size: 42px;
    font-weight: 700;
}

.active {
    position: relative;
    box-sizing: border-box;
}

@media only screen
and (max-device-width: 768px) {

    .container {
        width: 100%;
    }

    .slider_wrapper {
        display: none;
    }
}
